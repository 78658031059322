<template>
  <div class="reports">
    <ReportListsWinloseComponent
      :ref-key-text="refKeyText"
      :title="title"
      :url-path="urlPath"
      :route-name="routeName"
    />
  </div>
</template>

<script>
import ReportListsWinloseComponent from '@/components/ReportListsWinloseComponent.vue'

export default {
  components: {
    ReportListsWinloseComponent,
  },
  data() {
    return {
      refKeyText: 'Member',
      title: 'W/L Player (Simple)',
      urlPath: '/reports/v2/player_winlose_lists',
      routeName: 'reports-player-winlose-lists',
    }
  },
}
</script>

<style>

</style>
